import capterraLogo from '../../images/capterra-logo.svg';
import g2Logo from '../../images/g2-logo.svg';
import { ReviewBtnType } from './types';

export const REVIEW_BTN_LIST: ReviewBtnType[] = [
  {
    id: 'capterra-btn',
    icon: capterraLogo,
    href: 'https://www.capterra.com.sg/software/1036373/hubblebuild',
  },
  {
    id: 'g2-btn',
    icon: g2Logo,
    href: 'https://www.g2.com/products/hubble-build/reviews',
  },
];
