import React from 'react';
import Link from '../../Link';

interface LinkBtnProps {
  to: string;
  label: string;
}
const LinkBtn = ({ to, label }: LinkBtnProps) => {
  return (
    <Link
      to={to}
      className=" w-fit text-white text-[14px] px-4 py-[6px] bg-accent_blue_2 flex justify-center items-center gap-2 hover:text-white hover:bg-hubble_blue"
    >
      <span>{label}</span>
      <span className="fa-regular fa-arrow-right" />
    </Link>
  );
};

export default LinkBtn;
