import * as React from 'react';
import Layout from 'components/Layout';
import translation from 'intl';
import LanguageProvider from 'LanguageProvider';
import RequestForDemoCard from 'components/pages/requestForDemo';
import { graphql } from 'gatsby';
import SEOGraphQL from 'components/SEOGraphQL';
import { PageSEOType } from 'components/types';
import { PREFIX_CANONICAL_URL } from 'components/constant';
import BreadcrumbList from 'components/structuredData/BreadcrumbList';

const pageUrl = `${PREFIX_CANONICAL_URL}/request-for-demo`;

const RequestForDemoPage = () => {
  return (
    <LanguageProvider messages={translation}>
      <Layout headerStyle="light">
        <BreadcrumbList
          pageLabelId="structuredData.requestDemo"
          pageUrl={pageUrl}
        />
        <RequestForDemoCard />
      </Layout>
    </LanguageProvider>
  );
};
export default RequestForDemoPage;

export const Head = ({
  data,
}: {
  data: {
    allStrapiRequestDemo: {
      nodes: PageSEOType[];
    };
  };
}) => {
  return (
    <SEOGraphQL
      data={data.allStrapiRequestDemo.nodes[0]}
      canonicalURL={pageUrl}
    />
  );
};

export const query = graphql`
  query {
    allStrapiRequestDemo {
      nodes {
        seo {
          preventIndexing
        }
        SEO {
          metaDescription
          metaTitle
          metaSocial {
            title
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
