import React, { useEffect, useState } from 'react';
import LanguageProvider from 'LanguageProvider';
import translation from './translation';
import RequestDemoHero from './RequestDemoHero';
import NewsroomTestimonialScrollV2 from 'components/testimonial/newsroom/NewsroomTestimonialScrollV2';
import ConnectCard from 'components/connectCard';
import './style.css';
import tw from 'twin.macro';
import HubspotForm from 'components/HubspotForm';
import { FormTypes, NotificationTypes } from 'components/types';
import { GTM_DATA_LAYER } from 'components/constant';
import useNotification from 'components/notification/useNotification';
import ReachStats from './ReachStats';
import { graphql, useStaticQuery } from 'gatsby';
import { NewsroomTestimonialContentType } from 'components/testimonial/types';

interface Props {}

const HeroDemoAndForm = tw.div`
  px-[30px] py-[60px] lg:px-0 lg:pb-0  lg:h-fit grid grid-cols-1 lg:grid-cols-2 lg:gap-[64px] gap-16 mt-[84px]
`;

const index = (props: Props) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { showNotification } = useNotification({
    type: NotificationTypes.FORM,
    key: `request-demo`,
  });

  useEffect(() => {
    if (isSubmitted) {
      showNotification();
    }
  }, [isSubmitted]);

  const data = useStaticQuery(graphql`
    query {
      allStrapiProductOverview {
        nodes {
          CollateralReferencesHeader
          CollateralReferencesDescription
          CollateralReferencesCTAButtonLabel
          CollateralReferencesCTAButtonLink
          LeaveAReviewCTAButton
          CustomerCard {
            image {
              url
            }
            name
            testimonial
            title
          }
        }
      }
    }
  `);
  const {
    CollateralReferencesHeader,
    CollateralReferencesDescription,
    CollateralReferencesCTAButtonLabel,
    CollateralReferencesCTAButtonLink,
    LeaveAReviewCTAButton,
    CustomerCard,
  } = data.allStrapiProductOverview.nodes[0];
  const newsroomTestimonialContent: NewsroomTestimonialContentType = {
    title: CollateralReferencesHeader,
    description: CollateralReferencesDescription,
    btnLabel: CollateralReferencesCTAButtonLabel,
    btnLink: CollateralReferencesCTAButtonLink,
    reviewBtnLabel: LeaveAReviewCTAButton,
    cardList: CustomerCard.map(
      ({ name, title, testimonial, image }: Record<string, any>) => ({
        imgUrl: image.url,
        author: name,
        projectName: title,
        content: testimonial,
      }),
    ),
  };
  return (
    <LanguageProvider messages={translation}>
      <HeroDemoAndForm>
        <div className="lg:h-full flex flex-col gap-8 lg:gap-[64px]">
          <RequestDemoHero />
          <ReachStats />
        </div>
        <HubspotForm
          formId="c3043704-6f94-48b8-8cf6-0d5b9a9999cd"
          onFormSubmitted={() => {
            setIsSubmitted(true);
            if (typeof window !== 'undefined') {
              window?.dataLayer.push({
                event: GTM_DATA_LAYER[FormTypes.REQUEST_DEMO],
              });
            }
          }}
          onFormReady={() => {
            if (isSubmitted) setIsSubmitted(false);
          }}
        />
      </HeroDemoAndForm>

      <NewsroomTestimonialScrollV2 {...newsroomTestimonialContent} />
      <div className="connect-card-background">
        <ConnectCard haveBtn="href" location="map" />
      </div>
    </LanguageProvider>
  );
};

export default index;
