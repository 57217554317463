import React from 'react';
import { ReviewBtnType } from '../types';

interface ReviewBtnProps extends ReviewBtnType {}

const ReviewBtn = ({ id, href, icon }: ReviewBtnProps) => {
  return (
    <a href={href} target="_blank" data-testid={id}>
      <img src={icon} alt="logo" className="h-8 md:h-10 mt-2 md:mt-[10px]" />
    </a>
  );
};

export default ReviewBtn;
