import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import tw, { styled } from 'twin.macro';

const SubTitle = tw.p`
  text-[16px] lg:text-[18px] lg:font-[500] text-accent_blue_1 mb-0
`;
const Title = tw.h1`
  text-[36px] !font-[600] lg:text-[48px] lg:!font-[500] mb-6 text-accent_blue_1 lg:mb-9
`;
const DescriptionWrapper = styled.div`
  ${tw`text-subtext`}
  ul {
    list-style-position: inside;
  }
  * {
    ${tw`text-[16px] lg:text-[18px] mb-4`}
  }
`;
const BannerSubHeaderWrapper = tw.p`
text-[16px] lg:text-[18px] text-subtext font-[600] leading-[150%] mb-4 
`;

const RequestDemoHero = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiRequestDemo {
        nodes {
          BannerHeader
          BannerSubHeader
          PageHeader
          BannerDescription {
            data {
              BannerDescription
            }
          }
        }
      }
    }
  `);
  const {
    BannerHeader,
    BannerSubHeader,
    PageHeader,
    BannerDescription: {
      data: { BannerDescription },
    },
  } = data.allStrapiRequestDemo.nodes[0];
  return (
    <div>
      <SubTitle>{PageHeader}</SubTitle>
      <Title>{BannerHeader}</Title>
      <BannerSubHeaderWrapper>{BannerSubHeader}</BannerSubHeaderWrapper>
      <DescriptionWrapper
        dangerouslySetInnerHTML={{ __html: BannerDescription }}
      />
    </div>
  );
};

export default RequestDemoHero;
