import React, { CSSProperties } from 'react';
import { REVIEW_BTN_LIST } from '../constants';
import ReviewBtn from './ReviewBtn';

interface ReviewBtnListProps {
  containerStyle?: CSSProperties;
  reviewBtnLabel: string;
}
const ReviewBtnList = ({
  containerStyle,
  reviewBtnLabel,
}: ReviewBtnListProps) => {
  return (
    <div style={containerStyle} className="flex gap-3 md:gap-4 items-center">
      <p className="b4 m-0 text-black">{reviewBtnLabel}</p>
      <div className="flex gap-6">
        {REVIEW_BTN_LIST.map(btn => (
          <ReviewBtn key={btn.id} {...btn} />
        ))}
      </div>
    </div>
  );
};

export default ReviewBtnList;
