import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import tw from 'twin.macro';

interface BannerStatsType {
  header: string;
  description: string;
  id: string;
}

const ComponentWrapper = tw.div`
bg-[#FCF9EC] px-4 pt-6 pb-12 lg:p-6 lg:pb-[48px] text-center rounded-[10px]
`;
const Header = tw.p`
text-[20px] lg:text-[24px] font-[600] leading-[115%] text-[#C16A1B] mb-4
`;
const Description = tw.p`
text-[14px] leading-[21px] text-subtext mb-6 px-4 lg:px-2
`;
const AllStatWrapper = tw.div`
grid grid-cols-2 gap-6 lg:px-8
`;
const StatItemWrapper = tw.div`
bg-[#F9EFCC] p-[10px] text-base_text
`;
const StatHeader = tw.span`
font-[600] text-[24px] leading-[36px]
`;
const StatDescription = tw.span`
text-[16px] leading-[24px]
`;
const ReachStats = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiRequestDemo {
        nodes {
          PlatformStatisticBannerHeader
          PlatformStatisticBannerDescription
          PlatformStatisticBannerStats {
            header
            description
            id
          }
        }
      }
    }
  `);
  const {
    PlatformStatisticBannerHeader,
    PlatformStatisticBannerDescription,
    PlatformStatisticBannerStats,
  } = data.allStrapiRequestDemo.nodes[0];
  return (
    <ComponentWrapper>
      <Header>{PlatformStatisticBannerHeader}</Header>
      <Description>{PlatformStatisticBannerDescription}</Description>
      <AllStatWrapper>
        {PlatformStatisticBannerStats.map(
          ({ header, description, id }: BannerStatsType) => (
            <StatItemWrapper key={id}>
              <StatHeader>{header}</StatHeader>
              <br />
              <StatDescription>{description}</StatDescription>
            </StatItemWrapper>
          ),
        )}
      </AllStatWrapper>
    </ComponentWrapper>
  );
};

export default ReachStats;
